import SimpleBar from "simplebar"

class CustomSelect {
  constructor($wrapper, type = 'checkbox', callbackFunction) {
    this.$wrapper = $wrapper
    this.type = type
    this.$toggle = $wrapper.find('.js-custom-select-toggle')
    this.$optionsList = $wrapper.find('.js-custom-select-list')
    this.is_open = false

    this.$selectLabel = $wrapper.find('.js-custom-select-render')
    this.defaultValue = this.$selectLabel.text()
    this.resultText = (this.$wrapper.data('result-text')) ? this.$wrapper.data('result-text') : 'Выбрано - '

    if ($(this.$wrapper).is('[data-tree]'))
    {
      this.is_filter_open = false
      this.listID = $(this.$optionsList).find('.hummingbird-base').attr('id')
      this.listLI = $(this.$optionsList).find('.hummingbird-base').find('label').parent('li')
      this.$filterInput = $(this.$toggle).find('.js-treeview-filter')
      this.$filterMessage = $(this.$optionsList).find('.hummingbird-base').find('.js-treeview-filter-message')
      this.arFilter = this.getList(this.listLI)
      this.filterEvents()
    }
    else if ($(this.$wrapper).is('[data-search]'))
    {
      this.$options = this.$wrapper.find('.js-custom-select-options')
      this.$optionsSearchItems = this.$options.find(`[type='${this.type}']`)
      new SimpleBar(this.$options[0])
      this.is_filter_open = false
      this.$filterInputs = $(this.$optionsList).find('label')
      this.$filterInput = $(this.$toggle).find('.js-custom-select-filter')
      this.$filterMessage = $(this.$optionsList).find('.js-custom-select-message')
      this.arFilter = this.getList(this.$filterInputs)
      this.checkDefaultValue(this.$optionsSearchItems)
      this.filterEvents()
    }
    else
    {
      this.$optionsItems = $wrapper.find(`[type='${this.type}']`)
      this.checkDefaultValue()
    }

    if ($(this.$wrapper).is('[data-multi-check]')) {
      this.checkMultiInputs()
    }
    this.eventHandler()

    if (callbackFunction)
      callbackFunction(this)

    return this
  }

  getList(tree) {
    let resultMap= []
    tree.each( (i, e) => {
      let id = (this.$wrapper.is('[data-search]')) ? $(e).find('input').val() : $(e).find('input').data('id'),
        text = $(e).find('.checkbox__text').text()
      if (this.$wrapper.is('[data-search]') && !this.$wrapper.is('[data-multi-check]')) {
        text = $(e).find('.custom-select__label').text()
      }
      resultMap.push({
        id: id,
        text: text,
        el: e
      })
    })
    return resultMap
  }

  eventHandler() {
    const self = this

    this.$toggle.on('click', function () {
      if (!self.is_filter_open) self.toggleShowOptions()
      return false
    })

    if (
      !$(this.$wrapper).is('[data-tree]') &&
      !$(this.$wrapper).is('[data-search]')
      )
    {
      this.$optionsItems.on('change', function () {
        self.handlerChangeValue($(this))
      })
    }

    if ($(this.$wrapper).is('[data-search]'))
    {
      this.$filterInputs.on('change', function () {
        const $input = $(this).find('input')
        self.handlerChangeValue($input)
      })
    }

    $(document).on('click', function (e) {
      const el = $(self.$wrapper)

      if (
        !el.is(e.target) &&
        el.has(e.target).length === 0 &&
        self.is_open
      )
      {
        self.toggleShowOptions()
      }
    })
  }

  filterEvents() {
    const _this = this

    this.$toggle.on('mousemove', function() {
      if (_this.is_open) $(_this.$filterInput)
        .addClass('active')
        .focus()
    })

    this.$filterInput.on('mouseover', function() {
      _this.is_filter_open = true
    })

    this.$toggle.on('mouseout', function() {
      if (!_this.$filterInput.val().length && _this.is_open) {
        $(_this.$filterInput).removeClass('active')
      }
      _this.is_filter_open = false
    })

    this.$filterInput.on('keypress', function(e) {
      let regex = /[.а-яА-ЯёЁ0-9\s]/g
      if (!regex.test(e.key)) {
        return false
      }
    })

    _this.$filterInput.on('keyup', function(e) {
      let typeTxt = $(this).val().toLowerCase()

      if (typeTxt.length > 2) {
        let arFound = _this.arFilter.filter(e => e.text.toLowerCase().search(typeTxt) !== -1)

        if ($(_this.$wrapper).is('[data-search]')) {
          $(_this.$filterInputs).each((i, e) => $(e).hide())

          if (arFound.length) {
            _this.$filterMessage.html('').hide()
            arFound.forEach(e => {
              let label = $(e.el).find('label')
              if (_this.$wrapper.is('[data-search]') && !_this.$wrapper.is('[data-multi-check]')) {
                if ($(label).find('.custom-select__label').text().toLowerCase().search(typeTxt) == -1) {
                  $(label).hide()
                }
              }
              else {
                if ($(label).find('.checkbox__text').text().toLowerCase().search(typeTxt) == -1) {
                  $(label).hide()
                }
              }

              $(e.el).show()
            })
          }
          else {
            _this.$filterMessage.html('По запросу ничего не найдено').show()
          }
        }

        if ($(_this.$wrapper).is('[data-tree]')) {
          $(`#${_this.listID}`).attr('data-hummingbird-filtered', '')
          _this.listLI.each((i, e) => $(e).hide())

          if (arFound.length) {
            _this.$filterMessage.html('').hide()
            arFound.forEach(e => {
              let label = $(e.el).closest('li').find('label')
              if ($(label).find('.checkbox__text').text().toLowerCase().search(typeTxt) == -1) {
                $(label).hide()
              }
              $(e.el).show()
            })
          }
          else {
            _this.$filterMessage.html('По запросу ничего не найдено').show()
          }
        }
      }

      if ($(_this.$wrapper).is('[data-search]')) {
        if (typeTxt.length == 0) {
          _this.$filterMessage.html('').hide()
          $(_this.$filterInputs).each((i, e) => {
            $(e).show()
          })
        }
      }

      if ($(_this.$wrapper).is('[data-tree]')) {
        if (typeTxt.length == 0) {
          $(`#${_this.listID}`).removeAttr('data-hummingbird-filtered')
          _this.$filterMessage.html('').hide()
          $(_this.listLI).each((i, e) => {
            $(e).show()
          })
        }
      }

    })
  }

  toggleShowOptions() {
    this.is_open = !this.is_open
    $(this.$wrapper).toggleClass('custom-select--open')

    if (this.is_filter_open)
    {
      $(this.$filterInput).addClass('active')
    }
    else
    {
      $(this.$filterInput).removeClass('active')
    }
  }

  handlerChangeValue($input) {
    if (!this.$wrapper.is('[data-multi-check]')) {

      if (this.$wrapper.is('[data-search]'))
      {
        this.$filterInputs.each(function () {
          const $option = $(this).find('input')
          if ($option.val() !== $input.val()) $option.prop('checked', false)
        })
      }
      else
      {
        this.$optionsItems.each(function () {
          const $option = $(this)
          if ($option.val() !== $input.val()) $option.prop('checked', false)
        })
      }

      this.renderSelectedValue($input)
      this.is_open = true
      this.toggleShowOptions()
    }

    if ($(this.$wrapper).is('[data-multi-check]'))
    {
      this.checkMultiInputs()
    }

  }

  renderSelectedValue($input) {
    if ($input.is(':checked')) {
      const text = $input
        .closest('label')
        .find('.custom-select__label')
        .html()
      this.$selectLabel.html(text)
      this.$wrapper.addClass('custom-select--not-default')
    } else {
      this.$selectLabel.text(this.defaultValue)
      this.$wrapper.removeClass('custom-select--not-default')
    }
  }

  checkMultiInputs() {
    let arChecked = this.$filterInputs.filter((i,e) => $(e).find('input').prop('checked')),
        countChecked = arChecked.length
      if (countChecked)
      {
        this.$selectLabel.html(`${this.resultText} ${countChecked}`)
      }
      else
      {
        this.$selectLabel.text(this.defaultValue)
      }
  }

  checkDefaultValue(arr) {
    const self = this,
      arrOptions = (arr == undefined) ? this.$optionsItems : arr

    arrOptions.each(function () {
      if ($(this).prop('checked'))
        self.renderSelectedValue($(this))
    })
  }
}

export default CustomSelect
