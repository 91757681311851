const cobwebs = {
  professional: {
    wrapper: document.querySelector('.radar-chart__wrapper_professional'),
    description: document.querySelector('.radar__list_professional'),
    highlightIndex: 2,
    competenciesCount: 8,
    autoHighlight: true,
    highlightMapIndex: {
      1: 1,
      2: 2,
      3: 3,
      4: 5,
      5: 8,
      6: 7,
      7: 6,
      8: 4,
    }
  },
  personal: {
    wrapper: document.querySelector('.radar-chart__wrapper_personal'),
    description: document.querySelector('.radar__list_personal'),
    highlightIndex: 2,
    competenciesCount: 7,
    autoHighlight: true,
    highlightMapIndex: {
      1: 1,
      2: 2,
      3: 3,
      4: 5,
      5: 7,
      6: 6,
      7: 4,
    },
  }
};

const activateCompetence = (cobweb, forceIndex) => {
  let nextIndex;

  if(forceIndex) {
    cobweb.highlightIndex = cobweb.highlightMapIndex[forceIndex];
    nextIndex = forceIndex;
  }
  else {
    cobweb.highlightIndex = cobweb.highlightIndex < cobweb.competenciesCount ? cobweb.highlightIndex + 1 : 1;
    nextIndex = cobweb.highlightMapIndex[cobweb.highlightIndex];
  }

  const activeElement = cobweb.wrapper.querySelector('.radar-chart__competencies__item:not(.radar-chart__competencies__item_disabled)');
  const nextElement = cobweb.wrapper.querySelector(`.radar-chart__competencies__item_${nextIndex}`);

  const activeDescriptionElement = cobweb.description.querySelector('.radar__item:not(.radar__item_disabled)');
  const nextDescriptionElement = cobweb.description.querySelector(`.radar__item_${nextIndex}`);

  if(activeElement && nextElement) {
    activeElement.classList.add('radar-chart__competencies__item_disabled');
    nextElement.classList.remove('radar-chart__competencies__item_disabled');

    if(activeDescriptionElement && nextDescriptionElement) {
      activeDescriptionElement.classList.add('radar__item_disabled');
      nextDescriptionElement.classList.remove('radar__item_disabled');
    }
  }
}

const cobwebItemsInitEvents = () => {
  const onMouseEnter = (cobweb, index) => {
    cobweb.autoHighlight = false;

    activateCompetence(cobweb, index);
  };

  const onMouseLeave = (cobweb) => {
    cobweb.autoHighlight = true;
  };

  Object.values(cobwebs).forEach((cobweb) => {
    const competencies = cobweb.wrapper.querySelectorAll('.radar-chart__competencies__item > img');

    competencies.forEach((e, index) => {
      e.addEventListener('mouseenter', () => onMouseEnter(cobweb, index + 1));
      e.addEventListener('mouseleave', () => onMouseLeave(cobweb));
    });
  });
};

const cobwebAutoHighlight = () => {
  setInterval(() => {
    Object.values(cobwebs).forEach((cobweb) => {
      if(!cobweb.autoHighlight) return;

      activateCompetence(cobweb);
    });
  }, 2000);
};

const ExpressCompCheckPage =  {
  init() {
    if(document.querySelector('.page_promo_competence-express')) {
      cobwebAutoHighlight();
      cobwebItemsInitEvents();
    }
  }
}

export default ExpressCompCheckPage;