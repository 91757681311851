const sidebarNav = {

  init() {
    this.events()
  },

  events() {
    const _this = this,
      $side = $('.js-mob-side'),
      $profileBtn = $side.find('.js-mob-profile-btn'),
      $profileNav = $side.find('.js-mob-profile-nav')

    if ($profileNav.hasClass('active')) {
      $profileNav.slideDown()
    }

    $(document).on('click', '.js-menu-toggle', function() {
      $(this).toggleClass('open')
      $side.toggleClass('active')
      $(".js-menu-toggle-hamburger").toggleClass('active')
    })

    $profileBtn.on('click', function() {
      if ($(this).hasClass('active')) {
        $(this).removeClass('active')
        $profileNav.removeClass('active').slideUp()
      }
      else {
        $(this).addClass('active')
        $profileNav.addClass('active').slideDown()
      }
    })

  }

}

export default sidebarNav
