const dev = {

  init() {
    this.eventHandlers()
  },

  eventHandlers() {
    const self = this

    $(document).on('click', '.js-dropdown', function (e) {
      e.stopPropagation()
      if (!$(this).find('.dropdown-popup')[0].contains(e.target))
        $(this).toggleClass('active')
    })

    $(document).on('click', '.js-switch-section-btn', function () {
      const section = $(this).data('section')
      $('.js-switch-section').removeClass('active')
      $('.js-switch-section-' + section).addClass('active')
    })

    $(document).on('click', '.js-toggle-section', function () {
      const section = $(this).data('section')
      $(this).toggleClass('btn_toggle_active')
      $('.js-section-' + section).slideToggle().toggleClass('page-layout__section_hide')
    })
  },
}

export default dev
