class Accordion {
  constructor($wrapper) {
    this.$wrapper = $wrapper
    this.$btn = this.$wrapper.find('.js-accordion-button').eq(0)
    this.$content = this.$wrapper.find('.js-accordion-content').eq(0)
    this.isHash = $(this.$wrapper).is(location.hash)
    this.togglingNow = false

    this.eventHandler()
  }

  eventHandler() {
    const self = this

    if (this.isHash)
      this.activateTab()
    else
      if (self.$btn.hasClass('tabs__button_active')) $(self.$content).slideDown()

    this.$btn.on('click', function () {
      if (self.togglingNow) {
        return
      }
      self.togglingNow = true
      self.activateTab()
    })
  }

  activateTab() {
    $(this.$content).slideToggle({complete: ()=>{this.togglingNow = false}})
    this.$btn.toggleClass('tabs__button_active')
    this.$wrapper.toggleClass('active')
  }
}

export default Accordion
