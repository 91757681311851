const initClearableSearch = {
  
  init() {
    this.events()
  },

  events () {
    const $container = $('.form-search')
    
    function setClearableStateSearchBox (searchQuery, searchBox) {
      if (searchQuery.length > 0 && !searchBox.hasClass('clerable')) {
          searchBox.addClass('clerable');
      }
  
      if (searchQuery.length === 0) searchBox.removeClass('clerable');
    }
  
    $container.find('input[name=q]').each(function () {
      setClearableStateSearchBox(this.value, $(this).parent());
    })
    
    $container.each(function () {
      $(this).find('input[name=q]').on('input', function() {
        setClearableStateSearchBox(this.value, $(this).parent());
      })
    })
  
    $container.each(function () {
      $(this).find('.js-form-search-clear').on('click', function () {
        $(this).find('input[name=q]').val('').trigger('input')
        
        return false
      })
    })
  }
}

export default initClearableSearch
