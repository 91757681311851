class ToggleTariffs {
  constructor($wrapper) {
    this.$wrapper = $wrapper
    this.$tariff = this.$wrapper.find('.js-detail-tariff')
    this.$btn = this.$wrapper.find('.js-toggle-tariff')
    this.$btnText = this.$btn.find('.js-toggle-tariff-text')
    this.startText = (this.$btn.data('text-start')) ? this.$btn.data('text-start') : 'О продукте'
    this.endText = (this.$btn.data('text-end')) ? this.$btn.data('text-end') : 'Свернуть'
    this.eventHandler()
  }

  eventHandler() {
    const self = this

    this.$btn.click(function () {
      $(this).toggleClass('btn_toggle_active')

      if ($(this).hasClass('btn_toggle_active'))
        $(self.$btnText).html(self.endText)
      else
        $(self.$btnText).html(self.startText)

      self.$tariff.slideToggle()
    })
  }
}

export default ToggleTariffs
