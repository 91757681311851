import Swiper from 'swiper'
import Tips from './tips/index'

const sliders = {
  commonConf: {
    observer: true,
    observeParents: true,
    pagination: {
      el: '.slider-pagination',
      type: 'bullets',
      clickable: true,
    },
    on: {
      init() {
        const slides = Object.values(this.slides)

        if (Array.isArray(slides)) {
          slides.forEach(slide => {
            if (slide instanceof Element) {
              sliders.reInitTipsDuplicate(slide)
            }
          })
        }

        if (this.el.dataset.pause === 'hover') {
          this.el.addEventListener('mouseenter', () => {
            this.autoplay.stop();
          });

          this.el.addEventListener('mouseleave', () => {
            this.autoplay.start();
          });
        }
      },
      sliderMove() {
        sliders.setStopAfterTrigger(this)
      },
    },
  },
  config: {
    lk_notification: {
      slidesPerView: 1,
      spaceBetween: 0,
    },
    lk_notification_horizontal: {
      slidesPerView: 1,
      spaceBetween: 0,
    },
    lk_support: {
      slidesPerView: "auto",
      freeMode: true,
      spaceBetween: 24,
    },
    three: {
      slidesPerView: 3,
      spaceBetween: 20,
    },
    two: {
      slidesPerView: 1,
      spaceBetween: 20,
      autoHeight: true,

      breakpoints: {
        1025: {
          slidesPerView: 2,
          spaceBetween: 20,
        }
      }
    },
    anti_crisis: {
      slidesPerView: 1,
      spaceBetween: 20,

      breakpoints: {
        1025: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 20,
        }
      }
    },
    auto: {
      slidesPerView: 'auto',
      freeMode: true,
      spaceBetween: 12,

      breakpoints: {
        1025: {
          spaceBetween: 20
        }
      }
    },
    home_news: {
      slidesPerView: 1,
      spaceBetween: 20,
      speed: 500,
      autoHeight: true,
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
      }
    },
    default: {
      slidesPerView: 1,
      spaceBetween: 0,
    },
    gallery: {
      slidesPerView: 1,
      spaceBetween: 0,
      autoHeight: true,
    },
    events: {
      slidesPerView: 'auto',
      spaceBetween: 12,
    },
    competence: {
      slidesPerView: '3',
      spaceBetween: 0,
      breakpoints: {
        1279: {
          slidesPerView: 3,
          spaceBetween: 0,
          autoHeight: false
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 12,
          autoHeight: false
        },
        320: {
          slidesPerView: 1,
          spaceBetween: 12,
          autoHeight: false
        }
      }
    },
    eventsPromo: {
      slidesPerView: '2',
      spaceBetween: 12,
      breakpoints: {
        1025: {
          slidesPerView: 2,
          autoHeight: false
        },
        320: {
          slidesPerView: 1,
          autoHeight: false
        }
      }
    },
    accelerator: {
      slidesPerView: 'auto',
      spaceBetween: 12,
    },
    news: {
      slidesPerView: 1,
      slidesPerGroup: 1,
      spaceBetween: 12,
      autoHeight: true,

      breakpoints: {
        1025: {
          slidesPerView: 'auto',
          slidesPerGroup: 4,
          autoHeight: false,
        },
        767: {
          slidesPerView: 2,
          autoHeight: false,
        }
      }
    },
    base_knowledge: {
      slidesPerView: 3,
      spaceBetween: 24,
      speed: 500,
      autoHeight: false,
      breakpoints: {
        1100: {
          slidesPerView: 3,
        },
        768: {
          slidesPerView: 2,
        },
        320: {
          slidesPerView: 1,
        }
      },
      autoplay: {
        delay: 3000,
        disableOnInteraction: false
      }
    },
    software_detail: {
      slidesPerView: 3,
      spaceBetween: 28,
      autoHeight: false,
      breakpoints: {
        1100: {
          slidesPerView: 3,
        },
        768: {
          slidesPerView: 2,
        },
        320: {
          slidesPerView: 1,
        }
      }
    },
    software_promo: {
      slidesPerView: 6,
      spaceBetween: 12,
      autoHeight: false,
      breakpoints: {
        1100: {
          slidesPerView: 6,
        },
        768: {
          slidesPerView: 4,
        },
        320: {
          slidesPerView: 2,
        }
      },
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
      }
    },
    serv_dev: {
      slidesPerView: 6,
      slidesPerGroup: 6,
      spaceBetween: 12,
      autoHeight: false,
      breakpoints: {
        1150: {
          slidesPerView: 6,
          slidesPerGroup: 6
        },
        750: {
          slidesPerView: 4,
          slidesPerGroup: 4
        },
        550: {
          slidesPerView: 3,
          slidesPerGroup: 3
        },
        300: {
          slidesPerView: 2,
          slidesPerGroup: 2
        },
        150: {
          slidesPerView: 1,
          slidesPerGroup: 1
        },
        50: {
          slidesPerView: 1,
          slidesPerGroup: 1
        }
      },
      autoplay: {
        delay: 3000,
        disableOnInteraction: false
      }
    },
    serv_dev_olympiad: {
      slidesPerView: 6,
      slidesPerGroup: 6,
      spaceBetween: 12,
      autoHeight: false,
      breakpoints: {
        1150: {
          slidesPerView: 6,
          slidesPerGroup: 6
        },
        750: {
          slidesPerView: 3,
          slidesPerGroup: 3
        },
        550: {
          slidesPerView: 3,
          slidesPerGroup: 3
        },
        300: {
          slidesPerView: 2,
          slidesPerGroup: 2
        },
        150: {
          slidesPerView: 1,
          slidesPerGroup: 1
        },
        50: {
          slidesPerView: 1,
          slidesPerGroup: 1
        }
      },
      autoplay: {
        delay: 3000,
        disableOnInteraction: false
      }
    },
    collaboration_olympiad: {
      slidesPerView: 5,
      slidesPerGroup: 5,
      spaceBetween: 36,
      autoHeight: false,
      breakpoints: {
        1150: {
          slidesPerView: 5,
          slidesPerGroup: 5
        },
        750: {
          slidesPerView: 3,
          slidesPerGroup: 3,
          spaceBetween: 32
        },
        550: {
          slidesPerView: 2,
          slidesPerGroup: 2,
          spaceBetween: 32
        },
        300: {
          slidesPerView: 2,
          slidesPerGroup: 2,
          spaceBetween: 10
        },
        150: {
          slidesPerView: 1,
          slidesPerGroup: 1,
          spaceBetween: 10
        },
        50: {
          slidesPerView: 1,
          slidesPerGroup: 1,
          spaceBetween: 10
        }
      },
      autoplay: {
        delay: 3000,
        disableOnInteraction: false
      }
    },
    serv_dev_four: {
      slidesPerView: 4,
      slidesPerGroup: 4,
      spaceBetween: 12,
      autoHeight: false,
      breakpoints: {
        750: {
          slidesPerView: 4,
          slidesPerGroup: 4
        },
        550: {
          slidesPerView: 3,
          slidesPerGroup: 3
        },
        300: {
          slidesPerView: 2,
          slidesPerGroup: 2
        },
        150: {
          slidesPerView: 1,
          slidesPerGroup: 1
        },
        50: {
          slidesPerView: 1,
          slidesPerGroup: 1
        }
      },
      autoplay: {
        delay: 3000,
        disableOnInteraction: false
      }
    },
    marketplaces: {
      slidesPerView: 4,
      slidesPerGroup: 4,
      spaceBetween: 20,
      breakpoints: {
        1280: {
          slidesPerView: 4,
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        320: {
          slidesPerView: 2,
          spaceBetween: 10,
        }
      },
      autoHeight: false,
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
      }
    },
    partners: {
      slidesPerView: 3,
      slidesPerGroup: 3,
      spaceBetween: 20,
      autoHeight: false,
      autoplay: {
        delay: 3000,
        disableOnInteraction: false
      },
      breakpoints: {
        768: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        320: {
          slidesPerView: 2,
          spaceBetween: 12,
        }
      },
    },
    partners_meta: {
      slidesPerView: 4,
      slidesPerGroup: 4,
      spaceBetween: 20,
      autoHeight: false,
      autoplay: {
        delay: 3000,
        disableOnInteraction: false
      },
      breakpoints: {
        1136: {
          slidesPerView: 4,
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        320: {
          slidesPerView: 2,
          spaceBetween: 12,
        }
      },
    },
    conferenceEvent: {
      slidesPerView: 5,
      slidesPerGroup: 5,
      spaceBetween: 8,
      autoHeight: true,
    },
    businessIntro: {
      slidesPerView: 1,
      slidesPerGroup: 1,
      spaceBetween: 20,
      speed: 500,
      autoHeight: true,
      loop: true,
      autoplay: {
        delay: 8000,
        disableOnInteraction: true,
      },
    },
    businessCards: {
      slidesPerView: 3,
      spaceBetween: 32,
      autoHeight: true,
      breakpoints: {
        1279: {
          slidesPerView: 3
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 28,
        },
        320: {
          slidesPerView: 1
        }
      },
    },
    businessMentors: {
      slidesPerView: 3,
      spaceBetween: 32,
      autoHeight: true,
      breakpoints: {
        1279: {
          slidesPerView: 3
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 28,
        },
        320: {
          slidesPerView: 1
        }
      },
    },
    businessSnippet3d: {
      slidesPerView: 1,
      slidesPerGroup: 1,
      spaceBetween: 20,
      autoHeight: true,
    },
  },

  init() {
    this.eventHandlers()
  },

  eventHandlers() {
    const self = this
    const mapSliders = new Map()

    $('.js-slider').each(function (i, e) {
      const sliderId = $(e).attr('data-slider-id')
      mapSliders.set(sliderId)
    })

    if (mapSliders.size){

      for (const key of mapSliders.keys()) {
        const slider = new Swiper(`[data-slider-id=${key}]`, Object.assign(self.config[key], self.commonConf))

        if (Object.keys(self.config[key]).includes('autoplay')) {
          let autoplayDelay = Object.entries(self.config[key]).filter(e => e[0] === 'autoplay')[0][1].delay / 1000;
          if(slider.$el) {
            let $paginationBullets = $(slider.$el).find('.swiper-pagination-bullet'),
              $slides = slider.slides,
              arSlides = Array.from($slides)

            const filterSlidesDelay = arSlides.filter(slide => {
              return slide.hasAttribute('data-swiper-autoplay')
            })

            if (filterSlidesDelay.length > 0) {
              arSlides.forEach((slide, key) => {
                if (slide.dataset.swiperAutoplay) {
                  const autoplayDelay = parseInt(slide.dataset.swiperAutoplay) / 1000
                  $($paginationBullets[key]).addClass('swiper-pagination-bullet_timer').html(`<span class="swiper-pagination-bullet__timer-bckg" style="--timer-num: ${autoplayDelay + 's'};"></span>`)
                } else {
                  $paginationBullets.addClass('swiper-pagination-bullet_timer').html(`<span class="swiper-pagination-bullet__timer-bckg" style="--timer-num: ${autoplayDelay + 's'};"></span>`);
                }
              })
            } else {
              $paginationBullets.addClass('swiper-pagination-bullet_timer').html(`<span class="swiper-pagination-bullet__timer-bckg" style="--timer-num: ${autoplayDelay + 's'};"></span>`);
              if ($paginationBullets.length > 0) {
                $paginationBullets.each((key, bullet) => {
                  $(bullet).on('click', function() {
                    self.setStopAfterTrigger(slider)
                  })
                })
              }
            }
          } else if(slider.forEach){
            slider.forEach(sl => {
              let $paginationBullets = $(sl.$el).find('.swiper-pagination-bullet');
              $paginationBullets.addClass('swiper-pagination-bullet_timer').html(`<span class="swiper-pagination-bullet__timer-bckg" style="--timer-num: ${autoplayDelay + 's'};"></span>`);
            });
          }
        }

        if (slider instanceof Array) {
          // Обработка слайдеров, которые имеют одинаковый sliderId и добавляются в массив
          slider.forEach(e => {
            self.checkCountSlide(e);
            self.setEvents(e);
          })
        }
        else {
          self.checkCountSlide(slider);
          self.setEvents(slider);
        }

        self.saveSliders(slider)
      }
    }
  },

  arraySliders: [],
  saveSliders(slide){
    this.arraySliders.push(slide)
  },

  getSliders() {
    return this.arraySliders;
  },

  reInitTipsDuplicate(slide) {
    if ($(slide).hasClass('swiper-slide-duplicate')) {
      const tips = $(slide).find('[class*="js-tip-"]')
      if (tips.length > 0) {
        $(tips).each((key, tip) => {
          const startPosCls = tip.className.search(/js\-tip\-/)
          if (startPosCls !== -1) {
            const subClsName = tip.className.substring(startPosCls, tip.className.length),
              tipType = subClsName.replace(/js\-tip\-/, '')
            try {
              const instances = $.tooltipster.instances()
              if ($(tip).is('[data-tip-first]')) {
                const arFilter = instances.filter(inst => {
                  return $(inst._$origin[0]).is('[data-tip-first]')
                })
                if (arFilter.length > 0) {
                  arFilter.forEach(inst => {
                    Tips.initTip($(tip), tipType, inst.__Content)
                  })
                }
              }
            } catch (error) {
              console.log('Если вы читаете это, сообщите нашему разработчику об ошибке')
            }
          }
        })
      }
    }
  },

  setControls(slider) {
    let $btnPrev = $(slider.$el).find('.js-slider-prev')
    let $btnNext = $(slider.$el).find('.js-slider-next')
    $($btnPrev).on('click', () => {
      this.setStopAfterTrigger(slider)
      slider.slidePrev()
    })
    $($btnNext).on('click', () => {
      this.setStopAfterTrigger(slider)
      slider.slideNext()
    })
  },

  setStopAfterTrigger(slider) {
    if (slider.el.dataset.hasOwnProperty('stopAfterTrigger')) {
      if (!Boolean(slider.el.dataset.stopAfterTrigger)) {
        slider.el.dataset.stopAfterTrigger = true
        slider.autoplay.stop()
      }
    }
  },

  addActiveSlider(slider) {
    // При классе active появляется возможность добавлять классы для слайдера с количеством слайдов > 1
    // Slider-controls скрываем в стилях по дефолту, чтобы избежать дерганья при прогрузке скриптов
    // Если слайдов больше > 1, то добавляем видимость через класс active
    $(slider.$el).find('.swiper-wrapper').addClass( "active" );
    $(slider.$el).find('.slider-controls').addClass( "active" );
  },

  addDisabledSlider(slider) {
    // Если слайдов только 1, то добавляет disabled.
    // Можно прописать дефолтные стили для предотвращения дергания блоков при прогрузке скриптов слайда
    $(slider.$el).find('.swiper-wrapper').addClass( "disabled" );
  },

  setEvents(slider) {
    slider.on('slideChange', () => {
      const arActiveTips = document.querySelectorAll('.tooltipster-show')
      if (arActiveTips.length > 0) {
        arActiveTips.forEach((el) => {
          el.remove('tooltipster-show')
        })
      }
    })
    slider.on('slideChangeTransitionStart', function () {
      $(slider.$el).find('.js-tip-fh,.js-tip-fc').each((idx, el) => {
        $(el).tooltipster('hide');
        $(el).tooltipster('disable');
      });
    });
    slider.on('slideChangeTransitionEnd', function () {
      $(slider.$el).find('.js-tip-fh,.js-tip-fc').each((idx, el) => {
        $(el).tooltipster('enable');
      });
    });
  },

  checkCountSlide(slider) {
    // Формируем slidesPerView в зависимости от значения "auto"
    const slidesPerView = slider.params.slidesPerView === 'auto' ? slider.slides.length : slider.params.slidesPerView;
    // Если количество слайдов 'auto', считаем не количество слайдов, а их ширину и сравниваем с шириной общего контейнера
    const isSlidesFullWidth = slider.virtualSize > slider.width;
    if (
      (slider.params.slidesPerView === 'auto' && isSlidesFullWidth) ||
      slider.slides.length > slidesPerView ||
      slider.$el[0].dataset.sliderId === "gallery"
      ) {
      this.setControls(slider)
      this.addActiveSlider(slider)
    }
    else {
      this.addDisabledSlider(slider)
    }
  }
}

export default sliders
