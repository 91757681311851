import SimpleBar from "simplebar"
import Tips from './tips/index'
import CustomSelect from './custom-select'

if(!window.Spinner) {
  window.Spinner = { show: () => { // wait to init jquery and overwrite spinner methods
      setTimeout(()=>{
        if(window['Spinner'] && window['Spinner']['show']) {
          if(window['Spinner']['maxTimes'] > 0 || window['Spinner']['maxTimes'] == null) {
            window['Spinner']['show']();
            if(window['Spinner']['maxTimes']) window['Spinner']['maxTimes']--;
            clearTimeout(window.Spinner.currentTo);
            window.Spinner.currentTo = setTimeout(()=>{window.Spinner.hide()},500);
          }
        }
      },100);
    },
    hide: () => {
      setTimeout(()=>{
        window['Spinner']['hide']();
      }, 600);
    },
    currentTo: null,
    maxTimes: 5
  }
}

const common = {
  navwidth: 0,

  init() {
    this.eventHandlers()
    this.navToggle()
    this.tableHover()
  },

  eventHandlers() {
    const _this = this

    window.Spinner = { // global spinner
      show : ()=>{
        let spinner = $('.spinner');
        if(spinner[0]) {
          spinner.show();
        } else {
          $('body').append(`<div class="spinner" style="position: fixed;"></div>`);
        }
      },hide : ()=>{
        $('.spinner').hide();
      },
    };

    $(document).find('[data-class-if-more]').each((idx, el) => {
      let values = el.dataset.classIfMore.split(',');
      $(el).addClass(values[window.innerWidth > Number(values[0]) ? 1 : 2]);
    });

    $(document).on('click', function (e) {
      if ($('.js-dropdown').hasClass('active')) $('.js-dropdown').removeClass('active')
      if ($('.js-tooltip-custom').hasClass('tooltip-custom_active')) $('.js-tooltip-custom').removeClass('tooltip-custom_active')
    })

    $(document).on('click', '.js-tooltip-custom-toggle', function(e) {
      e.stopPropagation()

      const $tooltip = $(this).find('.js-tooltip-custom')
      if ($tooltip.hasClass('tooltip-custom_active')) {
        $tooltip.removeClass('tooltip-custom_active')
      }
      else {
        $('.js-tooltip-custom').removeClass('tooltip-custom_active')
        $tooltip.addClass('tooltip-custom_active')
      }
    })

    $(document).on('click', '.js-scroll-to', function(e) {
      e.preventDefault()
      const $el = $(this).attr('href')
      $('html, body').animate(
        {
          scrollTop: $($el).offset().top - 30
        },
        500
      )
    })

    $(document).on("click", '.js-toggle-show-more', function () {
      let idContent = '#' + $(this).data('show'),
        btnLabel = $(this).find('.js-toggle-show-more-lable')

      if ($(this).hasClass('btn-action_active')) {
        $(this).removeClass('btn-action_active')
        btnLabel.html('Показать все')
        $(idContent).slideUp()
      }
      else {
        $(this).addClass('btn-action_active')
        btnLabel.html('Свернуть')
        $(idContent).slideDown()
      }
    });

    $(document).on('keyup', '.js-confirm-code', function (e) {
      let code = '';
      $('.js-confirm-code').each(function (i, elem) {
        code += $(elem).val();
      });

      if (e.keyCode === 8)
        $(this).parent().prev().find('.js-confirm-code').focus();
      else
        $(this).parent().next().find('.js-confirm-code').focus();
    })

    let tooltipLinkFix = (instance, helper) => {
      let withWrapper = $(`<div>${instance.content()}</div>`);
      if(withWrapper.find('a[href]').length > 0) {
        $('.tooltipster-base[id*="tooltipster"]').css('pointer-events', 'unset');
      }
    };

    $(window).resize(() => {
      document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
    }).resize()


    if ($(document).find('.js-tip-big').length) {
      $('.js-tip-big').each((key, tip) => Tips.initTip($(tip), 'big'))
	  }

    if ($(document).find('.js-tip-medium').length) {
      $('.js-tip-medium').each((key, tip) => Tips.initTip($(tip), 'medium'))
	  }

	  if ($(document).find('.js-tip-small').length) {
      $('.js-tip-small').each((key, tip) => Tips.initTip($(tip), 'small'))
	  }

    if ($(document).find('.js-tip').length) {
      $('.js-tip').tooltipster({
        animation: 'grow',
        delay: 200,
        theme: 'tooltipster-shadow',
        contentAsHTML: true,
        trigger: 'click',
        interactive: true,
        position: 'top',
        functionReady: function(instance, helper) {
          let withWrapper = $(`<div>${instance.content()}</div>`);
          if(withWrapper.find('a[href]').length > 0) {
            $('.tooltipster-base[id*="tooltipster"]').css('pointer-events', 'unset');
          }
        } /* do not set min width 320 // */
      })
    }

    if ($(document).find('.js-tip-promo').length) {
      $('.js-tip-promo').tooltipster({
        animation: 'grow',
        delay: 200,
        theme: 'tooltipster-shadow tooltipster-promo',
        contentAsHTML: true,
        interactive: true,
        trigger: 'click',
        position: 'top',
        functionReady: tooltipLinkFix
      })
    }

    if ($(document).find('.js-tip-hover').length) {
      $('.js-tip-hover').tooltipster({
        animation: 'grow',
        delay: 200,
        theme: 'tooltipster-shadow tooltipster-accelerator',
        contentAsHTML: true,
        interactive: true,
        trigger: 'hover',
        position: 'top',
        functionReady: tooltipLinkFix
      })
    }

    if ($(document).find('.js-tip-olympiad').length) {
      $('.js-tip-olympiad').tooltipster({
        animation: 'grow',
        delay: 200,
        theme: 'tooltipster-shadow tooltipster-accelerator tooltipster-olympiad',
        contentAsHTML: true,
        trigger: 'custom',
        triggerOpen: {
          mouseenter: true,
          touchstart: true
        },
        triggerClose: {
            mouseleave: true,
            click: true,
            scroll: true,
            tap: true
        },
        position: 'top',
        functionReady: tooltipLinkFix,
      })
    }

    if ($(document).find('.js-tip-rgo').length) {
      $('.js-tip-rgo').tooltipster({
        animation: 'grow',
        delay: 200,
        theme: 'tooltipster-shadow tooltipster-rgo',
        contentAsHTML: true,
        trigger: 'click',
        triggerOpen: {
          mouseenter: true,
          touchstart: true
        },
        triggerClose: {
            mouseleave: true,
            click: true,
            scroll: true,
            tap: true
        },
        position: 'top',
        functionReady: tooltipLinkFix,
      })
    }

    if ($(document).find('.js-tip-rgo-big').length) {
      $('.js-tip-rgo-big').tooltipster({
        animation: 'grow',
        delay: 200,
        theme: 'tooltipster-shadow tooltipster-rgo tooltipster-rgo_big',
        contentAsHTML: true,
        trigger: 'click',
        triggerOpen: {
          mouseenter: true,
          touchstart: true
        },
        triggerClose: {
            mouseleave: true,
            click: true,
            scroll: true,
            tap: true
        },
        position: 'top',
        functionReady: tooltipLinkFix,
      })
    }

    if ($(document).find('.js-tip-small').length) {
      $('.js-tip-small').tooltipster({
        animation: 'grow',
        delay: 200,
        theme: 'tooltipster-shadow tooltipster-franchise',
        contentAsHTML: true,
        trigger: 'custom',
        triggerOpen: {
          mouseenter: true,
          touchstart: true
        },
        triggerClose: {
            mouseleave: true,
            click: true,
            scroll: true,
            tap: true
        },
        position: 'top',
        functionReady: tooltipLinkFix,
      })
    }

    // if ($(document).find('.js-tip-small').length) {
    //   $('.js-tip-small').tooltipster({
    //     animation: 'grow',
    //     delay: 200,
    //     theme: 'tooltipster-shadow tooltipster-small',
    //     contentAsHTML: true,
    //     trigger: 'custom',
    //     triggerOpen: {
    //       mouseenter: true,
    //       touchstart: true
    //     },
    //     triggerClose: {
    //         mouseleave: true,
    //         click: true,
    //         scroll: true,
    //         tap: true
    //     },
    //     position: 'top',
    //     functionReady: tooltipLinkFix,
    //   })
    // }

    if ($(document).find('.js-tip-purchase').length) {
      $('.js-tip-purchase').tooltipster({
        animation: 'grow',
        delay: 200,
        theme: 'tooltipster-shadow tooltipster-purchase',
        contentAsHTML: true,
        trigger: 'click',
        position: 'top',
        functionReady: tooltipLinkFix,
      })
    }

    if ($(document).find('.js-tip-info-business').length) {
      $('.js-tip-info-business').tooltipster({
        animation: 'grow',
        delay: 200,
        theme: 'tooltipster-shadow tooltipster-accelerator tooltipster-info-business',
        contentAsHTML: true,
        interactive: true,
        trigger: 'hover',
        position: 'top',
        functionReady: tooltipLinkFix
      })
    }

    if ($(document).find('.js-tip-authorization').length) {
      $('.js-tip-authorization').tooltipster({
        animation: 'grow',
        delay: 200,
        theme: 'tooltipster-shadow tooltipster-authorization',
        interactive: true,
        contentAsHTML: true,
        trigger: 'hover',
        position: 'bottom',
        functionReady: tooltipLinkFix
      })
    }

    /* start fast tooltips */
    function getTtOptions(customOpt, extraClass){
      return Object.assign({
        animation: 'grow',
        delay: 0,
        theme: 'tt-default ' + (extraClass ? extraClass : ''),
        trigger: 'click',
        contentAsHTML: true,
        interactive: true,
        speed: 100,
        position: 'top',
        arrowColor: '#ffffff'
      }, (customOpt || {}));
    }
    function ttBrakeWords(el, mLength){
      let maxLength = isNaN(mLength) ? 0 : mLength;
      let brake = txt => {
        let res = [];
        txt.split(' ').forEach(w => {
          if(w.length < maxLength) {
            res.push(w);
          } else {
            let cutIdx = Math.round(w.length / 2);
            res.push(w.slice(0,cutIdx) + '&shy;' + w.slice(cutIdx, w.length));
          }
        });
        return res.join(' ');
      };
      el.title = brake(el.title);
    }
    $($(document).find('.js-tip-fh')).each((idx, el) => {
      if(el.dataset.ttBrakeWords) ttBrakeWords(el, Number(el.dataset.ttBrakeWords));
      let offsetY = Number(el.dataset.ttOffsetY) || 0;
      offsetY = isNaN(offsetY) ? 0 : offsetY;
      $(el).tooltipster(getTtOptions({offsetY, trigger: 'custom', theme: 'tooltipster-shadow tooltipster-employes', triggerOpen: { mouseenter: true, touchstart: true }, triggerClose: { mouseleave: true, click: true, scroll: true, tap: true },}, el.dataset.ttClass));
    });
    $($(document).find('.js-tip-fc')).each((idx, el) => {
      if(el.dataset.ttBrakeWords) ttBrakeWords(el, Number(el.dataset.ttBrakeWords));
      let offsetY = Number(el.dataset.ttOffsetY) || 0;
      offsetY = isNaN(offsetY) ? 0 : offsetY;
      $(el).tooltipster(getTtOptions({offsetY, trigger: 'click'}, el.dataset.ttClass));
    });

    $(window).on('resize', function() {
      _this.navToggle()
    });

    $('.js-addFields').each((index, el) => {
      const parent = $(el).parent();
      const wrapper = $(el).parents('.input-group-wrapper');
      const max = parent.data('max-field');

      if (wrapper.find('[data-field-template]').length >= max) {
        parent.css('display', 'none');
      }
    });

    $(document).on('click', '.js-addFields', (e) => {
      const parent = $(e.currentTarget).parent();
      const wrapper = $(e.currentTarget).parents('.input-group-wrapper');
      const tpl = parent.find('script');
      const max = parent.data('max-field');

      e.preventDefault();

      parent.before(tpl.html());

      const select = parent.prev().find('.js-custom-select');
      const simplebar = select.find('[data-simplebar]');

      new CustomSelect(select);

      if (simplebar.length > 0) {
        new SimpleBar(simplebar[0]);
      }

      updateTemplateInputsName();

      hideCheckedOptions();

      if (wrapper.find('[data-field-template]').length >= max) {
        parent.hide()
      }

      $('.js-cyrillicInput').on('change input', bindCyrillicHandler);
    });

    function updateTemplateInputsName() {
      let count = 1;

      $('.js-addressBuildingGroup').each((index, group) => {
        $('[data-field-template]', group).each((i, el) => {
          const textName = $(el).find('.input').attr('name');
          const checkboxName = $(el).find('.custom-select__option input').attr('name');

          count++;

          $(el).find('.input').attr('name', textName.replace(/[0-9]/g, count));
          $(el).find('.custom-select__option input').attr('name', checkboxName.replace(/[0-9]/g, count));
        });
      });
    }

    hideCheckedOptions();

    $(document).on('click', '.js-addedFieldDel', (e) => {
      const wrapper = $(e.currentTarget).parents('.input-group-wrapper');
      const max = wrapper.find('[data-max-field]').data('max-field');

      $(e.currentTarget).parents('.input-group-block').remove();

      if (wrapper.find('[data-field-template]').length < max) {
        wrapper.find('.input-group-add').show();
      }

      hideCheckedOptions();
      updateTemplateInputsName();

      setTimeout(() => {
        if (typeof inputsAutocomplete !== 'undefined') {
          inputsAutocomplete.changeTotalAddress()
        }
      }, 200);
    });

    window.initOkpdSearch = (select) => {
      if(!select || !select[0]) {
        select = $('select[data-search-for="okpd"]');
      }
      select.each((idx, el) => {
        if(el.dataset && el.dataset.sumoEnabled){
          return;
        }
        let isInit = true;
        let sumoSelect = $(el);

        const config = {
          el: $(el),
          page: 1,
          existIds: [],
          prevText: '',
          parent: null,
          searchInput: null,
          tOut: null,
          reqProgress: false,
          sumo: null,
          code: ''
        };

        let onGetOkpdRes = (response, page, cb) => {
          let okved = response.data.list;
          config.parent.find('li.searching').remove();
          if (!Object.keys(okved || []).length && page < 2) {
            $('p.no-match').show();
            return;
          }
          if(isInit) {
            isInit = false;
            if(sumoSelect.data('val') && sumoSelect.data('text')) {
              let val = `${sumoSelect.data('val')}`;
              let text = sumoSelect.data('text');
              config.sumo.add(val, `${val} ${text}`);
              config.existIds.push(val);
              setTimeout(()=>{
                sumoSelect[0].sumo.selectItem(val);
              },750);
            }
          }

          for (let xmlId in okved) {
            if(okved.hasOwnProperty(xmlId) && config.existIds.indexOf(xmlId) == -1) {
              config.sumo.add(xmlId, `${xmlId} ${okved[xmlId]}`);
              config.existIds.push(xmlId);
            }
          }
          setTimeout(() => {
            let options = config.parent.find('ul.options li.opt');
            options.detach();
            config.parent.find('.simplebar-content').append(options);
          }, 0);
          cb && cb();
        };

        let getOkpd = (txt, page, code, cb) => {
          if(config.reqProgress){
            return;
          }
          config.reqProgress = true;
          BX.ajax.runComponentAction('nota:retail.order', 'getOKPD', {
            mode: 'class',
            data: {codeOrName: (txt || ''), page: page, type: 'OKPD2', code: (code || '')}
          }).then(res => {
            config.reqProgress = false;
            onGetOkpdRes(res, page, cb);
          }).catch(e => {
            console.error(e);
          });
        };

        sumoSelect.SumoSelect({placeholder: 'Выберите из списка', search : true, searchText: "Поиск",
          noMatch: 'Нет совпадении для "{0}"', forceCustomRendering: true});

        setTimeout(() => {
          const simpleBar = new SimpleBar(sumoSelect[0].sumo.E.parent().find('.optWrapper ul.options')[0]);
          simpleBar.getScrollElement().addEventListener('scroll', e => {
            if(!config.reqProgress && ((e.target.clientHeight + e.target.scrollTop + 110) > e.target.scrollHeight)) {
              config.page += 1;
              getOkpd(config.prevText, config.page, config.code);
            }
          });

          config.parent = $(el).closest('.SumoSelect');
          config.searchInput = config.parent.find('input.search-txt');
          config.sumo = el.sumo;
          $(el).on('change', e => {
            let inpGroup = $(e.target).closest('.input-group.input-group_error');
            if(inpGroup[0]) {
              inpGroup.removeClass('input-group_error');
            }
          });
          config.searchInput.on('keyup', e => {
            setTimeout(() => {
              config.parent.find('li.opt').remove();
            },200);
            let noMatch = $('p.no-match');
            noMatch.hide();
            if(!config.parent.find('li.searching')[0]){
              config.parent.find('.simplebar-content').append('<li class="searching" style="padding: 12px 16px;">Поиск...</li>');
            }
            if(config.tOut) {
              clearTimeout(config.tOut);
            }
            config.tOut = setTimeout(() => {
              config.page = 1;
              config.existIds = [];
              config.prevText = e.target.value;
              config.code = /^[\d\.]{1,}$/.test(e.target.value) ? 'code' : 'text';
              getOkpd(config.prevText, config.page, config.code);
            }, 1000);
          });
          getOkpd('', 1, 'text', ()=>{
            setTimeout(()=>{
              config.sumo.unSelectAll();
            },500);
          });
        },500);
      });
    };
    window.initOkpdSearch();

    $(document).on('change', '.js-addressCheckeboxVisible', (e) => {
      const groupBlock = $(e.currentTarget).parents('[data-input-group]');

      if ($(e.currentTarget).is(':checked')) {
        groupBlock.removeClass('is_hide_input_fields');
        groupBlock.find('.input, .custom-select input').prop('disabled', false);
      } else {
        const selectRender = groupBlock.find('.js-custom-select-render');
        groupBlock.addClass('is_hide_input_fields');
        selectRender.text(selectRender.data('placeholder'));
        groupBlock.find('.input').val('');
        groupBlock.find('.input').prop('disabled', true);
        groupBlock.find('.custom-select input').prop({
          disabled: true,
          checked: false,
        });
        groupBlock.find('.js-addedFieldDel').trigger('click');

        setTimeout(() => {
          if (typeof inputsAutocomplete !== 'undefined') {
            inputsAutocomplete.changeTotalAddress()
          }
        }, 200);
      }
    });

    $(document).on('change', '.js-addressBuildingGroup input', (e) => {
      hideCheckedOptions();
    });

    function hideCheckedOptions () {
      const addressBuildingGroup = $('.js-addressBuildingGroup');
      const checked = addressBuildingGroup.find('.js-custom-select-list input:checked');
      const ids = [];

      addressBuildingGroup.find('.custom-select__option').show();

      checked.each((i, el) => {
        ids.push($(el).val())
      });

      $.each(ids, (i, id) => {
        addressBuildingGroup.find('input').each((i, el) => {
          if ($(el).val() == id) {
            $(el).parent().hide()
          }
        });
      });
    }

    $(document).on('change input', '.js-numberInput', function(){
      if (this.value.match(/[^0-9]/g)) {
        this.value = this.value.replace(/[^0-9]/g, '')
      }

      return false;
    });

    $(document).on('keydown', '.js-latinInput', function(e){
      if (!e.key.match(/[a-z@0-9_.\-]/i)) {
        e.preventDefault();
      }
    });

    $(document).on('paste', '.js-latinInput', function(e){
      let paste = (event.clipboardData || window.clipboardData).getData('text');

      if (!paste.match(/[a-z@0-9_.\-]/i)) {
        e.preventDefault();
      }
    });

    $('.js-cyrillicInput').on('change input', bindCyrillicHandler);

    function bindCyrillicHandler() {
      const re = /[a-zA-Z]/g;

      if (this.value.match(re)) {
        this.value = this.value.replace(re, '')
      }

      return false;
    }

    $(document).on('click', '.js-addressGroupRowValidate .custom-select__option', (e) => {
      const select = $(e.currentTarget).parents('.js-addressGroupRowValidate')
      select.removeClass('is-error')
      select.find('.input-group__error').remove()
    })

    $(document).on('change', '#treeview-selected-id, #treeview2-selected-id', (e) => {
      $(e.currentTarget).parents('.js-custom-select').removeClass('is-error')
    })

    $('[readonly]').each((index, el) => {
      if (!$(el).is('[data-label-undisabled]')) {
        $(`label[for="${$(el).attr('id')}"]`).addClass('is_disabled');
      }
    })
  },

  tableHover() {
    $('.js-table-hover td, .js-table-hover th').hover(function () {
      $('.js-table-hover td:nth-child(' + ($(this).index() + 1) + ')').addClass('hover');
      $('.js-table-hover th:nth-child(' + ($(this).index() + 1) + ')').addClass('hover');
    },function () {
      $('.js-table-hover td:nth-child(' + ($(this).index() + 1) + ')').removeClass('hover');
      $('.js-table-hover th:nth-child(' + ($(this).index() + 1) + ')').removeClass('hover');
    });
  },

  navToggle() {

  }

}

export default common
