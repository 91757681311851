import SimpleBar from "simplebar";

class Table {
  constructor($wrapper) {
    this.$wrapper = $wrapper
    this.$tables = $wrapper.find('table')
    this.arTr = []

    this.observer = new ResizeObserver(entries => {
      for (let entry of entries) {
        if(entry.contentBoxSize) {
          const contentBoxSize = Array.isArray(entry.contentBoxSize) ? entry.contentBoxSize[0] : entry.contentBoxSize;
          if (contentBoxSize.blockSize) {
            this.init()
          }
        } else {
          if (entry.contentRect && entry.contentRect.height > 0) {
            this.init()
          }
        }
      }
    })
    this.observer.observe(this.$wrapper[0]);
  }

  init() {
    const _this = this

    this.$tables.each((i,table) => {
      if (i) $(table).find('tr').each((index, el) => this.arTr.push($(el).outerHeight()))
    })

    this.$tables.each((i,table) => {
      if (!i)
        $(table).find('tr').each((index, el) => $(el).height(this.arTr[index]))
      else
        $(table).find('tr').each((index, el) => $(el).height() < this.arTr[index] ? $(el).height(this.arTr[index]) : $(el).height())
    })

    let tableScroll = $(this.$wrapper).find('.table-scroll'),
        tableHead = $(tableScroll).find('.js-table-wrapper-head'),
        tableHeadTop = tableHead.offset().top + $(tableHead).height(),
        tableBottom = _this.$wrapper.height() + _this.$wrapper.offset().top,
        lastScroll = 0,
        headerHeight = getComputedStyle(document.documentElement).getPropertyValue('--header-height')

    new SimpleBar(tableScroll[0])
    headerHeight = Number(headerHeight.slice(0, headerHeight.indexOf('px')))

    let parent = $(tableHead).parent(),
        arThWidth = []

    $(tableHead).find('th').each((i, el) => {
      arThWidth.push($(el).outerWidth())
    })

    if (!parent.find('.table__thead-clone').length) {
      let cloneTHEAD = $(tableHead).clone()
      cloneTHEAD.addClass('table__thead-clone').removeClass('js-table-wrapper-head')
      parent.append(cloneTHEAD)
      parent.find('.table__thead-clone').find('th').each((i,e) => {
        $(e).css('width', arThWidth[i])
      })
    }

    $(window).on('scroll', function() {
      let scrollTop = $(this).scrollTop(),
          scrollBottom = $(this).height() + scrollTop,
          tableStart = $(document).height() - $(this).height() - _this.$wrapper.height() - scrollTop,
          isHeader = (lastScroll < scrollTop) ? 0 : headerHeight

      if (tableHeadTop < scrollTop) {
        $('.table__thead-clone').css('top', scrollTop - tableHeadTop + $(tableHead).outerHeight() + isHeader)
      }
      else
        $('.table__thead-clone').css('top', "0px")

      if (scrollTop > tableStart)
        $(tableScroll).addClass('table-scroll_start-fixed')
      else {
        $(tableScroll).removeClass('table-scroll_start-fixed')
        tableHead.removeClass('thead_fixed')
      }

      if (scrollBottom > tableBottom) {
        $(tableScroll).removeClass('table-scroll_start-fixed')
      }

      lastScroll = scrollTop
    })
  }
}

export default Table
