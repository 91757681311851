import autosize from 'autosize'
class ListTabs {

  constructor($wrapper) {
    this.$wrapper = $wrapper
    this.$btns = $wrapper.find('.js-list-step-btns')
    this.$btnAdd = $wrapper.find('.js-list-step-add')
    this.$btnNext = $wrapper.find('.js-list-step-next')
    this.$btnPrev = $wrapper.find('.js-list-step-prev')
    this.$form = $wrapper.find('.js-list-step-form')
    this.$textarea = $wrapper.find('.js-textarea')

    this.events()
  }

  events() {
    const self = this
    this.$btnAdd.on("click", function () {
      self.$btns.hide()
      self.$form.show()
      if (self.$textarea.length > 0) {
        self.autosizeUpdate(self.$textarea)
      }
    })
    this.$btnPrev.on("click", function () {
      self.$btns.show()
      self.$form.hide()
      self.scrollTo(self.$btns)
      if (self.$textarea.length > 0) {
        self.autosizeUpdate(self.$textarea)
      }
    })
  }

  scrollTo(el) {
    $('html, body').animate({
      scrollTop: $(el).offset().top - 120
    }, 0)
  }

  autosizeUpdate(ta) {
    ta.each((i, item) => {
      autosize.update(item)
    })
  }
}

export default ListTabs
