import SimpleBar from "simplebar"

const indexNews = {

  init() {
    this.events()
  },

  events() {
    const $sideFilterWrapper = document.getElementById('js-news-filter-wrapper'),
      $filterCounter = document.getElementById('js-news-filter-counter')

    if ($sideFilterWrapper && $filterCounter) {
        let arChecked = [],
            sideFilterInputs = $sideFilterWrapper.getElementsByTagName('input')

        arChecked =[...sideFilterInputs].filter( e => e.checked)
        if (arChecked.length) {
            $filterCounter.innerHTML = arChecked.length
        } else {
          $($filterCounter).hide();
        }
    }

    $(document).on('click', '.js-filters-toggle', function() {
      if (($('.js-header').hasClass('header_show') && $('.js-header').hasClass('header_fixed')) || $(window).scrollTop() == 0) {
        $('.js-filters-side').removeClass('header_out')
      }
      else {
        $('.js-filters-side').addClass('header_out')
      }
    })

    $(document).on('click', '.js-news-side-toggle', function() {
      const side = $(this).data('side'),
        action = $(this).data('action')

      if (($('.js-header').hasClass('header_show') && $('.js-header').hasClass('header_fixed')) || $(window).scrollTop() == 0) {
        $('#js-news-'+side).removeClass('header_out')
      }
      else {
        $('#js-news-'+side).addClass('header_out')
      }

      if (action == 'open') {
        let $scrollbar = $('#js-news-'+side).find('.js-scrollbar')
        $('html, body').css('overflow', 'hidden')
        $('#js-news-'+side).addClass('active')
        new SimpleBar($('#js-news-'+side+'-wrapper')[0])

        if ($scrollbar.length) {
          new SimpleBar($scrollbar[0])
        }
      }
      else {
        $('html, body').css('overflow', 'initial')
        $('#js-news-'+side).removeClass('active')
      }
    })

    $(document).on('click', '#js-news-filter-wrapper, #js-news-sidebar-wrapper', function(e) {
      e.stopPropagation()
    })
    $(document).on('click', '#js-news-filter, #js-news-sidebar', function() {
      $(this).removeClass('active')
      $('html, body').css('overflow', 'initial')
    })
  }
}

export default indexNews
