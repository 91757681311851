class ScrollElement {

  constructor($wrapper) {
    this.$wrapper = $wrapper
    this.$el = this.$wrapper.find('.js-scroll-el')
    this.$elClassName = this.$el.data('class')
    this.elFixedTop = 25

    this.eventHandler()
    this.observer = new ResizeObserver(entries => {
      for (let entry of entries) {
        if(entry.contentBoxSize) {
          const contentBoxSize = Array.isArray(entry.contentBoxSize) ? entry.contentBoxSize[0] : entry.contentBoxSize;
          this.offsetHeight = contentBoxSize.blockSize
        } else {
          console.log(entry)
        }
      }
    })
    this.observer.observe(this.$wrapper[0]);
  }

  eventHandler() {
    const self = this

    self.offsetStart = self.$wrapper.offset().top
    self.offsetEnd = self.getOffsetEnd(self.$wrapper[0].offsetHeight)

    $(window).on('scroll', function() {
      let scrollTop = $(this).scrollTop()
      self.offsetEnd = self.getOffsetEnd(self.offsetHeight)

      if (scrollTop >= self.offsetEnd) {
        self.$el.css('top', self.offsetEnd - self.offsetStart)
        self.$el.removeClass(self.$elClassName + '_state_fixed')
      }
      else if (scrollTop > self.offsetStart) {
        self.$el.addClass(self.$elClassName + '_state_fixed')
        self.$el.removeAttr('style')
      }
      else {
        self.$el.removeClass(self.$elClassName + '_state_fixed')
      }
    })


  }

  getOffsetEnd(offsetHeight) {
    return this.offsetStart + offsetHeight - this.$el[0].offsetHeight - this.elFixedTop / 2
  }

}

export default ScrollElement
