const Tips = {
  initTip(el, tipType, content = null) {
    const _this = this
    switch (tipType) {
      case 'big':
        el.attr('data-tip-content', content ? content : el.attr('title'))
        if (window.innerWidth > 425) {
          el.tooltipster(this.getSettingsTip(tipType, el.data('tip-trigger-hover')))
            .tooltipster('content', content ? content : el.attr('title'))
        } else {
          $(document).on('click', '.js-tip-big', function () {
            _this.initJsTipBig(this.dataset)
            return false
          })
        }
        break
      default:
        el.attr('title', content ? content : el.attr('title'))
        el.tooltipster(this.getSettingsTip(tipType, el.data('tip-trigger-hover')))
          .tooltipster('content', content ? content : el.attr('title'))
        break
    }
  },

  getSettingsTip(tipType, isHover) {
    const _this = this
    return {
      animation: 'grow',
      delay: 200,
      theme: `tooltipster-shadow tooltipster_default tooltipster_default_${tipType}`,
      contentAsHTML: true,
      interactive: true,
      trigger: isHover !== undefined ? 'hover' : 'click',
      position: 'top',
      functionReady: _this.tooltipLinkFix,
    }
  },

  tooltipLinkFix(instance, helper) {
    let withWrapper = $(`<div>${instance.content()}</div>`);
    if(withWrapper.find('a[href]').length > 0) {
      $('.tooltipster-base[id*="tooltipster"]').css('pointer-events', 'unset');
    }
  },

  initJsTipBig(params) {
    let id = (new Date()).getTime()
    let html = `<div class="tip-big__overlay" id="${id}">`
    html += `<div class="tip-big__modal">`
    html += `<div class="tip-big__move"></div>`
    if (params.tipTitle !== undefined) {
      html += `<div class="tip-big__title">${params.tipTitle}</div>`
    }
    html += `<div class="tip-big__content">${params.tipContent}</div>`
    if (params.tipBtnEvent !== undefined && params.tipBtnText !== undefined) {
      html += `<a href="javascript:${params.tipBtnEvent}" class="tip-big__btn btn btn_view_square btn_size_medium_second btn_color_purple">${params.tipBtnText}</a>`
    } else if (params.tipBtnLink !== undefined && params.tipBtnText !== undefined) {
      html += `<a href="${params.tipBtnLink}" class="tip-big__btn btn btn_view_square btn_size_medium_second btn_color_purple">${params.tipBtnText}</a>`
    }
    html += `</div></div>`
    openModalTip(html, id)

    $(document).on('click', '.tip-big__overlay', function (event) {
      let target = $(event.target);
      if (!target.closest('.tip-big__modal').length) {
        closeModalTip()
      }
    });

    function initEvents(wrapper) {
      wrapper.find('.tip-big__move').on('touchmove', function (e) {
        moveModalTip(e)
      })
      wrapper.find('.tip-big__move').on('mouseup touchend', function () {
        closeModalTip()
      })
    }

    function moveModalTip(e) {
      $('.tip-big__modal').css({
        'animation': 'none',
        'transform': 'translateY(0%)',
        'top': `${e.originalEvent.changedTouches[0].clientY}px`
      })
    }

    function closeModalTip() {
      $('.tip-big__modal').addClass('slideDown')
      setTimeout(() => {
        $('html').css('overflow', 'initial')
        $('.tip-big__overlay').remove()
      }, 300)
    }

    function openModalTip(html, id) {
      $('html').css('overflow', 'hidden')
      $(html).appendTo('body')
      initEvents($(`#${id}`))
    }
  }
}
window.mspTips = Tips
export default Tips
