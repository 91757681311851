import Dropzone from "dropzone"

function initDropzone() {
  Dropzone.autoDiscover = false
  $('.js-dropzone').each(function () {
    const url = $(this).data('url'),
      idOutput = '#' + $(this).data('id-output'),
      $dropzoneLabelHtml = $(this).find('.js-dropzone-label')[0],
      $dropzoneLabelHtmlMob = $(this).find('.js-dropzone-label-mob')[0]

    let files = null,
      size = $(this).data('size') || 5,
      maxFiles = $(this).data('max-files') || 5,
      fileTypes = null,
      $errorSection = null;

    files = $(this).data('files') || ".jpg,.jpeg,.png,.tiff,.pdf";
    fileTypes = [];
    files.split(',').forEach(ft => {
      fileTypes.push(new RegExp('\\'+ft+'$'));
    });

    let dZone = new Dropzone($(this)[0], {
      url: url,
      autoQueue: false,
      previewsContainer: idOutput,
      uploadMultiple: true,
      maxFiles: maxFiles,
      maxFilesize: size,
      acceptedFiles: files ? files : ".jpg,.jpeg,.png,.tiff,.pdf",
      clickable: [$dropzoneLabelHtml, $dropzoneLabelHtmlMob],
      previewTemplate: `
        <div class="list-files__item">
          <div class="btn-action list-files__name">
            <svg class="icon icon-doc ">
              <use xlink:href="#doc"></use>
            </svg>
            <span data-dz-name></span>
            <span class="btn-action__label list-files__name-label" data-dz-size></span>
          </div>
          <div class="list-files__actions">
            <a download="" href="" class="btn-action list-files__action" data-dz-dload>
              <svg class="icon icon-download">
                <use xlink:href="#download"></use>
              </svg>
              <span>Скачать</span>
            </a>
            <button class="btn-action list-files__action" type="button" data-dz-remove>
              <svg class="icon icon-close ">
                <use xlink:href="#close"></use>
              </svg>
              <span>Удалить</span>
            </button>
          </div>
        </div>
      `,
      init: function() {
        let errorTexts = [];
        let tOut = null;
        let filesAdded = 0;
        this.on("addedfile", file => {
          filesAdded = dZone.getAcceptedFiles().length > filesAdded ? dZone.getAcceptedFiles().length : filesAdded;
          let meetLimit = filesAdded >= maxFiles;
          let isAccepted = false;
          fileTypes.forEach(ft => {
            if(ft.test(file.name) && (file.size < (size*1000000))) {
              isAccepted = true;
            }
          });
          if(!$errorSection){
            const $el = $($(this)[0].element);
            $errorSection = $el.find('.input-file__error');
          }
          $($(this)[0].element).removeClass('is-error').removeClass('required-error').removeClass('input-group_error');
          $($(this)[0].element).closest('.input-group_error').removeClass('input-group_error')
          if (isAccepted && !meetLimit) {
            filesAdded++;
          } else {
            dZone.removeFile(file);
            if(meetLimit){
              if(errorTexts.length == 0 || errorTexts[0].indexOf('Возможное количество файлов для загрузки') < 0) {
                errorTexts.unshift(`Возможное количество файлов для загрузки: ${maxFiles}`);
              }
            } else {
              errorTexts.push(`Файл ${file.name} не был прикреплен. Неверный формат или размер файла.`);
            }
          }
          const blob = new Blob([file], { type: file.type });
          $(file.previewTemplate).find('[data-dz-dload]').attr('download', file.upload.filename);
          $(file.previewTemplate).find('[data-dz-dload]').attr('href', URL.createObjectURL(blob));
          $(file.previewTemplate).find('[data-dz-size]').each((idx,el) => {
            let $el = $(el);
            if(/\sb$/.test(el.textContent)){
              $el.html($el.html().replace(/\sb$/, ' Бт'));
            } else if(/\sKB$/.test(el.textContent)) {
              $el.html($el.html().replace(/\sKB$/, ' Кб'));
            } else if(/\sMB$/.test(el.textContent)) {
              $el.html($el.html().replace(/\sMB$/, ' Мб'));
            }
          });
          if(tOut) clearTimeout(tOut);
          tOut = setTimeout(()=>{
            filesAdded = 0;
            if(errorTexts.length > 0) {
              $errorSection.html(`
              <div class="list-files">
                <div class="list-files__name">
                  ${ meetLimit ? '' : '<svg class="icon icon-doc"><use xlink:href="#doc"></use></svg>'}
                  <span>${errorTexts.join('</span><br/><svg class="icon icon-doc"><use xlink:href="#doc"></use></svg><span>')}</span>
                </div>
              </div>
            `)
            } else {
              $errorSection.html('');
            }
            errorTexts = [];
          }, 100);
        });
        this.on("removedfile", function (file) {
          const $el = $($(this)[0].element),
            $errorSection = $el.find('.input-file__error')
          if (file.accepted) {
            $el.removeClass('is-error')
            $errorSection.html('')
          }
        })
      }
    })

  })
}

export default initDropzone
