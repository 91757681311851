import SimpleBar from 'simplebar'
/* eslint-disable no-undef */
window.openModal = (name, _this) => {
  $.magnificPopup.open({
    items: {
      src: name
    },
    showCloseBtn: false,
    removalDelay: 300,
    mainClass: 'mfp-fade',
    fixedContentPos: true,
    callbacks: {
      beforeOpen: function () {
        $(name)
          .addClass('fadeInUp')
          .removeClass('fadeOutUp')

        if (window.hasOwnProperty('BX')) {
          BX.onCustomEvent("onOpenModal", [{
            id: (name.prop ? name.prop('id') : (
              typeof name === 'string' ? name.replace('#', '') : null
            ))
          }]);
        }
        let scrollbar = $(name).find('.js-scrollbar-modal')
        if (scrollbar.length) {
          new SimpleBar(scrollbar[0])
        }
        if (name == '#modal_gallery') {
          $(this.bgOverlay).addClass('modal-gallery')
          $(this.wrap).addClass('modal-gallery')
          $('.js-body').css({
            'touch-action': 'none'
          })
          const slider = $(`[data-slider-id="gallery"]`)
          if (slider.length > 0) {
            slider[0].swiper.slideTo(parseInt($(_this).data('img-key')), 1000, false)
          }
        }
      },
      beforeClose: function () {
        $(name)
          .addClass('fadeOutUp')
          .removeClass('fadeInUp')
        if (name == '#modal_gallery') {
          $(this.bgOverlay).removeClass('modal-gallery')
          $(this.wrap).removeClass('modal-gallery')
          $('.js-body').css({
            'touch-action': 'initial'
          })
        }
      }
    }
  })

  return false
}

window.closeModal = () => {
  $.magnificPopup.close()
}

window.message = content => {
  const title = content.title ? content.title : ''
  const text = content.text ? content.text : ''

  $('.js-message-modal-btns').html('')
  $('.js-message-modal-title').html(title)
  $('.js-message-modal-description').html(text)

  if (content.btns) {
    if (content.btns.length > 1) {
      $('.js-message-modal-btns').addClass('modal__btns_multyple')
    }
    else {
      $('.js-message-modal-btns').removeClass('modal__btns_multyple')
    }
    content.btns.forEach((btn, i) => {
      let layoutBtn = ''
      const color = btn.color ? btn.color : 'purple'

      if (btn.type === 'link') {
        const href = btn.href ? btn.href : '/'
        layoutBtn = `
          <a
            href="${href}"
            class="btn btn_view_round btn_color_${color} btn_size_medium_second modal__btns-btn">${btn.title}</a>
        `
        $('.js-message-modal-btns').append(layoutBtn)
      }
      else {
        layoutBtn = `
          <button
            id="js-modal-btn-${i}"
            class="btn btn_view_round btn_color_${color} btn_size_medium_second modal__btns-btn"
            type="button">${btn.title}</button>
        `
        $('.js-message-modal-btns').append(layoutBtn)
        $(document).find(`#js-modal-btn-${i}`).on('click', btn.cb)
      }
    })
  }
  else {
    $('.js-message-modal-btns').html(`
      <button
        class="btn btn_view_round btn_color_purple btn_size_medium_second modal__btns-btn js-close-modal"
        type="button">Понятно</button>
    `)
  }
  openModal('#message-modal')
}

window.messageError = content => {
  const title = content.title ? content.title : ''
  const description = content.description ? content.description : ''
  $('.js-error-modal-title').html(title)
  $('.js-error-modal-description').html(description)
  openModal('#error-modal')
}

window.messageSuccess = content => {
  const title = content.title ? content.title : ''
  const description = content.description ? content.description : ''
  $('.js-success-modal-title').html(title)
  $('.js-success-modal-description').html(description)
  openModal('#success-modal')
}

const modalHandler = {
  init() {
    this.event_handler()
    this.immediately_modal()
  },

  event_handler() {
    const self = this

    $(document).on('click', '.js-close-modal', function () {
      self.close_modal()
    })

    $(document).on('click', '.js-open-modal', function () {
      const id = $(this).data('modal')

      if (id)
        openModal(id, $(this))

      return false
    })

    $(document).on('click', '.js-video-modal', function () {
      const src = $(this).data('video')
      $.magnificPopup.open({
        items: {
          src: src
        },
        disableOn: 700,
        type: 'iframe',
        removalDelay: 300,
        mainClass: 'mfp-fade'
      })

      return false
    })
  },

  immediately_modal() {
    // Появление модалки при загрузке страницы
    // Добавляем на страницу input(type="hidden", name="immediately_modal" value="#{id modal name}")
    // У модалки прописываем #{id modal name}
    if($('[name="immediately_modal"]').length){
      const id = $('[name="immediately_modal"]').val();
      openModal(id);
    }
  },

  close_modal() {
    $.magnificPopup.close()
  }
}

const modalGallery = {
  init() {
    this.event_handler()
  },

  event_handler() {
    let bulletsNum = null
    const changeBullet = bulletIndex => {
      $('.js-modal-gallery-bullet').removeClass('swiper-pagination-bullet-active')
      $('.js-modal-gallery-bullet').each((i, bullet) => {
        if (bulletIndex === i) {
          $(bullet).addClass('swiper-pagination-bullet-active')
        }
      })
    }

    $('.js-modal-gallery').each(function () {
      $(this).magnificPopup({
        delegate: '.js-modal-gallery-photo',
        type: 'image',
        tLoading: 'Загрузка изображения #%curr%...',
        mainClass: 'modal-gallery mfp-fade',
        callbacks: {
          beforeOpen: function() {
            $('.js-body').css({
              'touch-action': 'none'
            })
          },
          beforeClose: function() {
            $('.js-body').css({
              'touch-action': 'initial'
            })
          },
          change: function() {
            setTimeout(() => {
              const num = (bulletsNum) ? bulletsNum : $(this.content).find('.js-modal-gallery-bullet').length - 1,
                bulletIndex = this.index > num ? 0 : this.index
              changeBullet(bulletIndex)
            }, 0)
          },
          open: function() {
            const mp = $.magnificPopup.instance,
              btnLeft = $(this.content).find('.js-modal-gallery-left'),
              btnRight = $(this.content).find('.js-modal-gallery-right'),
              btnClose = $(this.content).find('.js-modal-gallery-close'),
              pagination = $(this.content).find('.js-modal-gallery-pagination'),
              index = mp.index

              mp.items.forEach(item => {
                const activeClass = item.index === index ? ' swiper-pagination-bullet-active' : ''
                pagination.append(`
                  <span class="js-modal-gallery-bullet swiper-pagination-bullet modal-gallery__pagination-bullet${activeClass}"></span>
                `)
              })
              bulletsNum = $(this.content).find('.js-modal-gallery-bullet').length - 1

              $(btnClose).on('click', function() {
                mp.close()
              })
              $(btnLeft).on('click', function() {
                const bulletIndex = mp.index - 1 < 0 ? bulletsNum : mp.index - 1
                changeBullet(bulletIndex)
                mp.prev()
              })
              $(btnRight).on('click', function() {
                const bulletIndex = mp.index + 1 > bulletsNum ? 0 : mp.index + 1
                changeBullet(bulletIndex)
                mp.next()
              })
          },
        },
        gallery: {
          enabled: true,
          navigateByImgClick: true,
          preload: [0, 1],
          arrowMarkup: ''
        },
        image: {
          markup: `
            <div class="mfp-figure modal-gallery__mfp-figure">
              <button type="button" class="modal-gallery__close js-modal-gallery-close">
                <svg class="icon icon-close modal-gallery__close-icon">
                  <use xlink:href="#close"></use>
                </svg>
              </button>
              <div class="modal-gallery__wrapper">
                <div class="mfp-title modal-gallery__title"></div>
                <div class="mfp-img"></div>
                <div class="modal-gallery__footer">
                  <button type="button" class="slider-control modal-gallery__control js-modal-gallery-left">
                    <svg class="icon icon-left modal-gallery__control-icon">
                      <use xlink:href="#left"></use>
                    </svg>
                  </button>
                  <div class="slider-pagination modal-gallery__pagination js-modal-gallery-pagination"></div>
                  <button type="button" class="slider-control modal-gallery__control js-modal-gallery-right">
                    <svg class="icon icon-right modal-gallery__control-icon">
                      <use xlink:href="#right"></use>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          `,
          tError:
            '<a href=" % url % ">Изображение #%curr%</a> не может быть загружено.',
          titleSrc: function(item) {
            return $(item.el[0]).attr('title')
          }
        }
      })
    })

    if($('.js-modal-photo').length) {
      $('.js-modal-photo').magnificPopup({
        type: 'image',
        closeOnContentClick: true,
        image: {
          verticalFit: false
        },
        mainClass: 'mfp-fade modal-gallery'
      })
    }
  }
}

export { modalHandler, modalGallery }
